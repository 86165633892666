.login input {
  display: block;
  border-radius: 17px;
  width: 75%;
  height: 8.1%;
  position: relative;
  margin: 0 auto;
  border-style: none;

  padding: 0.5rem;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-214269);
}

.login input + input {
  margin-top: 0.8rem;
}

.login input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

.login input.error {
  border-color: red;
}

.login .input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.login-background-image {
  background-image: url("../assets/bg-map.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative; */
  padding-top: 8%;
}

.loginLink {
  text-decoration: none;
  display: block;
  color: var(--col-214269);
}
